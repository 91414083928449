<template>
  <Popup
    class="notes"
    :modal-id="modalId"
    :on-close="onModalClose"
    size="lg"
    :v-center="true"
  >
    <template v-slot:header>{{ labels.note }}</template>
    <template v-slot:body>
      <textarea class="form-control" rows="3"></textarea>
    </template>
    <template v-slot:footer>
      <div>{{ footerText }}</div>
      <div>
        <Button
          class="btn-custom"
          :label="labels.close"
          variant="btn-gray"
          data-dismiss="modal"
          @click="onModalClose"
        />
        <Button
          class="btn-custom"
          :label="labels.save"
          variant="btn-sienna"
          @click="saveNotice"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button.vue";
import { mapGetters } from "vuex";
export default {
  name: "Notes",
  components: {
    Popup,
    Button,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    footerText: {
      type: String,
      default: "",
    },
  },
  methods: {
    onModalClose() {
      console.log("Modal closed");
      // this.$router.go( -1 );
    },
    saveNotice() {
      console.log("Save action");
    },
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.notes {
  textarea {
    border-radius: 10px;
  }

  ::v-deep .modal-footer {
    justify-content: space-between;

    > * {
      margin: 0;
    }
  }

  .btn-custom {
    color: $white;
    border-radius: 5px;
    display: inline-block;
    padding: 11px 20px;
    text-align: center;
    border: none;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 2px 11px -1px #00000038;
    text-decoration: none;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
    }

    &:last-child {
      margin-left: 15px;
    }

    @media (min-width: 1200px) {
      &:last-child {
        margin-left: 30px;
      }
    }

    @media (min-width: 1600px) {
      padding: 11px 30px;
      font-size: 16px;

      &:last-child {
        margin-left: 50px;
      }
    }
  }
}
</style>
