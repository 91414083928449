<template>
  <SubscriptionCard class="agreement">
    <FormulateInput
      v-model="checkboxValue"
      type="checkbox"
      @change="toggleCheckbox"
    />

    <span>
      {{ title }}
      <router-link :to="link">{{ linkTitle }}</router-link>
      <p>
        {{ termsOfAgreement }}
      </p>
    </span>
  </SubscriptionCard>
</template>

<script>
import SubscriptionCard from "@/components/Subscription/SubscriptionCard";

export default {
  name: "SubscriptionAgreement",
  components: {
    SubscriptionCard,
  },
  props: {
    title: {
      type: String,
      default: "Es gilt unsere",
    },
    link: {
      type: [Object, String],
      default: "/agb",
    },
    linkTitle: {
      type: String,
      default: "Nutzungsvereinbarung",
    },
    termsOfAgreement: {
      type: String,
      default:
        "Der Verbraucher stimmt ausdrücklich zu, dass der Unternehmer mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und bestätigt seine Kenntnis davon, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.",
    },
  },
  data() {
    return {
      checkboxValue: false,
    };
  },
  methods: {
    toggleCheckbox() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.agreement {
  display: flex;
  .formulate-input {
    margin-right: 15px;
  }

  p {
    color: $black;
  }
}
</style>
