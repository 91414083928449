<template>
  <Popup
    v-if="loggedOut"
    class="wrapper"
    ref="popupExampleRef"
    modal-id="warningPopup"
    size="xl"
    aria-hidden="false"
    border-radius="5px"
    :v-center="true"
  >
    <template v-slot:header
      ><h1>{{ title }}</h1></template
    >
    <template v-slot:body>
      <div class="notification">
        <p>{{ description }}</p>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        class="btn-custom"
        label="Warnung schließen"
        variant="btn-purple"
        data-dismiss="modal"
      />
    </template>
  </Popup>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Popup from "@/components/Popups/Popup.vue";
export default {
  name: "WarningPopup",
  components: { Button, Popup },
  props: {
    loggedOut: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Warnung",
    },
    description: {
      type: String,
      default:
        "Du bist ausgeloggt worden, da eine neue Session auf einem anderen Gerät oder in einem anderem Browser gestartet wurde..",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
::v-deep .modal-content {
  padding: 25px;
  .modal-header,
  .modal-footer {
    padding: 0;
    border: none;
  }
  .close {
    display: none;
  }

  .modal-header {
    h1 {
      font-size: 35px;

      @media (max-width: 991.98px) {
        font-size: 20px;
      }
    }
  }

  .modal-body {
    padding-top: 5px !important;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;

    p {
      font-size: 18px;
      color: $black;
      @media (max-width: 991.98px) {
        font-size: 16px;
      }
    }
  }

  .btn-custom {
    color: $white !important;
    font-weight: 600 !important;
    &:hover {
      background-color: $white !important;
      outline: 1px solid $purple;
      color: $purple !important;
      font-weight: 600 !important;
      filter: none !important;
    }
  }
}
</style>
