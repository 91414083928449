<template>
  <Popup
    class="remark-popup"
    :modal-id="modalId"
    :on-close="onModalClose"
    size="lg"
  >
    <template v-slot:header>{{ labels.add_for }}: {{ headerText }}</template>
    <template v-slot:body>
      <FormulateInput
        v-model="noteTitle"
        type="text"
        id="formulate-input-text"
        name="title"
        :label="labelTitle"
        :placeholder="titlePlaceHolder"
        error-behavior="live"
      ></FormulateInput>
      <FormulateInput
        v-model="noteContent"
        type="textarea"
        id="formulate-input-textarea"
        :label="labelContent"
        :placeholder="contentPlaceHolder"
        error-behavior="live"
      ></FormulateInput>
    </template>
    <template v-slot:footer>
      <div class="buttons">
        <Button
          class="btn-custom btn-silver"
          :padding="`11px 20px`"
          :fontSize="`0.875rem`"
          :label="concludeLabel"
          data-dismiss="modal"
          @click="onModalClose"
        ></Button>
        <Button
          class="btn-custom btn-slateblue"
          :padding="`11px 20px`"
          :fontSize="`0.875rem`"
          :label="submitLabel"
          @click="saveNotice"
        ></Button>
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";
import { mapGetters } from "vuex";

export default {
  name: "RemarkPopup",
  components: {
    Popup,
    Button,
  },
  props: {
    headerText: {
      type: String,
      default: "",
    },
    labelTitle: {
      type: String,
      default: "",
    },
    labelContent: {
      type: String,
      default: "",
    },
    titlePlaceHolder: {
      type: String,
      default: "",
    },
    contentPlaceHolder: {
      type: String,
      default: "",
    },
    concludeLabel: {
      type: String,
      default: "Conclude",
    },
    submitLabel: {
      type: String,
      default: "Submit",
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      noteTitle: "",
      noteContent: "",
    };
  },
  methods: {
    onModalClose() {
      console.log("Modal closed");
    },
    saveNotice() {
      console.log("Save action");
    },
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.remark-popup {
  ::v-deep {
    .modal-dialog {
      .modal-content {
        .modal-header {
          padding: 30px;

          @media (min-width: 1600px) {
            padding: 50px;
            font-size: 1.5rem;
          }

          .close {
            outline: none;
          }
        }

        .modal-body {
          padding: 30px 30px 0 30px;

          @media (min-width: 1600px) {
            padding: 50px 50px 0 50px;
          }

          .formulate-input {
            &-label {
              font-size: 1rem;
              margin-bottom: 0.938rem;

              @media (min-width: 1600px) {
                font-size: 1.125rem;
              }
            }

            &-element {
              max-width: 100%;
              margin-bottom: 50px;
            }
          }

          #formulate-input {
            &-text {
              font-size: 0.875rem;

              &:hover,
              &:focus {
                border-color: $navy;
              }

              @media (min-width: 1600px) {
                font-size: 1rem;
              }
            }

            &-textarea {
              height: 103px;
              font-size: 0.875rem;

              &:hover,
              &:focus {
                border-color: $navy;
              }

              @media (min-width: 1600px) {
                font-size: 1rem;
              }
            }
          }
        }

        .modal-footer {
          padding: 30px;

          @media (min-width: 1600px) {
            padding: 50px;
          }

          .buttons {
            .btn-silver {
              margin-right: 50px;
            }

            .btn-silver,
            .btn-slateblue {
              @media (min-width: 1600px) {
                font-size: 1rem;
                padding: 11px 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
