<template>
  <div class="profile-upload-image">
    <FormulateInput
      type="image"
      name="image"
      :value="value"
      :uploader="fakeUploader"
      validation="mime:image/jpeg,image/png,image/gif"
      @change="onChangeInput"
    ></FormulateInput>
    <img class="preview__img" v-if="value && value != ''" :src="value" />
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="currentColor"
      class="bi bi-person-circle preview__img"
      viewBox="0 0 16 16"
    >
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#C5C5C5" />
      <path
        fill-rule="evenodd"
        fill="#C5C5C5"
        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
      />
    </svg>

    <Button :label="btnLabel" variant="btn-purple" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";

export default {
  name: "ProfileUploadImage",
  components: {
    Button,
  },
  props: {
    btnLabel: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      image: {},
      changed: false,
    };
  },
  methods: {
    onChangeInput() {
      if (this.editable) {
        this.changed = true;
      }
    },
    async fakeUploader(image) {
      if (this.editable) {
        if (this.changed) {
          this.image = image;
          this.imageChanged();
        }
        return Promise.resolve({});
      }
    },
    imageChanged() {
      const fileReader = new FileReader();

      fileReader.addEventListener("loadend", () => {
        this.$emit("changed", fileReader.result);
      });

      fileReader.readAsDataURL(this.image);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.profile-upload-image {
  position: relative;
  width: 240px;

  button {
    position: absolute;
    right: 0;
    top: 3px;
    bottom: 3px;
    height: 45px;
    width: 165px;
    padding: 0;
    font-weight: 500;
  }
  .preview__img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    position: absolute;
    top: 0;
  }

  ::v-deep .formulate-input {
    height: 50px;
    margin-bottom: 0;

    .formulate-input-upload-area {
      height: 50px;
      padding: 0;
      &-mask {
        display: none;
        //   opacity: 0;
      }

      &[data-has-files] {
        input {
          display: block;
        }
      }

      input {
        left: unset;
        top: 50%;
        transform: translateY(-50%);
        height: 45px;
        width: 165px;
        cursor: pointer;

        &::-webkit-file-upload-button {
          cursor: pointer;
        }

        &:focus,
        &:hover {
          & ~ .formulate-input-upload-area-mask::before {
            background-color: $white;
          }
        }
      }

      .formulate-files {
        .formulate-file {
          // flex-direction: column;
          border: none;
          padding: 0;

          .formulate-file-image-preview {
            // width: 50px;
            height: 50px;
            flex: 0 0 50px;
            position: static;
            box-shadow: none;

            &:hover {
              transform: scale(1);
            }

            img {
              position: static;
              object-fit: cover;
            }
          }

          .formulate-file-progress,
          .formulate-file-remove,
          .formulate-file-name {
            display: none;
          }
        }
      }

      .formulate-input-upload-area-mask {
        border: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: #c5c5c5;

        &:before {
          top: 10px;
          width: 50px;
          height: 50px;
          background-color: $white;
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 400,400"><path fill="%23B0AFB5" d="M180.145 11.731 C 106.090 27.825,85.266 130.224,147.645 171.543 L 154.696 176.213 149.555 177.178 C 115.176 183.627,67.259 225.865,48.212 266.511 C 35.262 294.146,26.988 354.890,35.073 362.975 C 39.344 367.246,360.732 367.043,364.602 362.768 C 374.013 352.368,364.772 291.333,349.113 260.469 C 329.729 222.262,275.193 177.068,247.919 176.611 C 246.007 176.578,248.082 174.149,254.169 169.292 C 328.347 110.101,272.684 -8.380,180.145 11.731 "/></svg>') !important;
          -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 400,400"><path fill="%23B0AFB5" d="M180.145 11.731 C 106.090 27.825,85.266 130.224,147.645 171.543 L 154.696 176.213 149.555 177.178 C 115.176 183.627,67.259 225.865,48.212 266.511 C 35.262 294.146,26.988 354.890,35.073 362.975 C 39.344 367.246,360.732 367.043,364.602 362.768 C 374.013 352.368,364.772 291.333,349.113 260.469 C 329.729 222.262,275.193 177.068,247.919 176.611 C 246.007 176.578,248.082 174.149,254.169 169.292 C 328.347 110.101,272.684 -8.380,180.145 11.731 "/></svg>') !important;
        }
      }
    }
  }
}
</style>
