<template>
  <div
    v-if="image || name || profession"
    class="team-card recht-col col-xl-3 col-lg-4 col-md-3 col-12"
  >
    <div class="team-card-content">
      <img v-if="image" class="card-image" :src="image" alt="image" />
      <div
        v-if="name || profession"
        class="recht-col-content team-card-content-body"
      >
        <h6 v-if="name" class="card-title">
          {{ name }}
        </h6>
        <p v-if="profession" class="card-text">
          {{ profession }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamCard",
  props: {
    image: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    profession: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.team-card {
  font-family: Montserrat;

  &-content {
    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      background-color: $slateblue;
    }

    &-body {
      border: none;
      color: #313131;
      @include transition--ease-out;

      text-align: center;
      padding: 15px 25px 30px;

      h6 {
        font-weight: 700;
        font-size: 20px;
        margin: 15px 0px 0px;
      }

      p {
        margin: 15px 0px 0px;
        font-weight: normal;
        font-size: 16px;
      }
    }

    &:hover,
    &:focus {
      @include transition--ease-out;
      box-shadow: 0px 10px 30px rgb(0 0 0 / 13%);
      background: $white;
    }
  }
}
</style>
