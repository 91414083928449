<template>
  <div class="module container-fluid">
    <div class="module-row row">
      <div class="module-column col-lg p-0">
        <h1
          :class="[
            'module__title',
            { 'with-icon': data && data.iconLabel ? data.iconLabel : false },
          ]"
        >
          <span v-if="preTitle">{{ preTitle }}: </span>{{ title }}
        </h1>
        <div class="module__footer">
          <label class="status">{{ statusTitle }}: {{ subTitle }}</label>
          <label
            class="parent"
            v-if="data && data.parent && Object.keys(data.parent).length"
          >
            {{ data.parent.preTitle }}
            <router-link v-if="data.parent.link" :to="data.parent.link">
              {{ data.parent.title }}
            </router-link>
            <template v-else>{{ data.parent.title }}</template>
          </label>
        </div>
        <div class="module__label-icon" v-if="data && data.iconLabel">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="130"
            height="62"
            viewBox="0 0 130 62"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stop-color="#c667fc" />
                <stop offset="1" stop-color="#6258f1" />
              </linearGradient>
            </defs>
            <g id="_3038" data-name="3038" transform="translate(12092 -3567)">
              <rect
                id="_1734"
                data-name="1734"
                width="130"
                height="62"
                rx="5"
                transform="translate(-12092 3567)"
                fill="url(#linear-gradient)"
              />
              <text
                id="Free"
                transform="translate(-12027 3613)"
                :fill="`${colorsHtml.white}`"
                font-size="42"
                font-family="Roboto-Bold, Roboto"
                font-weight="700"
              >
                <tspan x="-41.395" y="0">
                  {{ data.iconLabel }}
                </tspan>
              </text>
            </g>
          </svg>
        </div>
        <hr class="m-0 mb-3" />
      </div>
      <div class="button" v-if="data && data.buttonLabel">
        <Button class="btn-custom btn-slateblue" :label="data.buttonLabel" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import colorsHtml from "/colors.config.json";
export default {
  name: "ModuleHeader",
  components: {
    Button,
  },
  props: {
    preTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    statusTitle: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.module {
  position: relative;
  transition: all 0.3s ease;
  max-width: 1070px;
  margin-bottom: 35px;

  hr {
    margin: 0 !important;
  }

  &__title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;

    &.with-icon {
      max-width: calc(100% - 120px);
    }

    span {
      color: $darkgray;
      font-weight: 400;
    }

    @media (min-width: 1200px) {
      font-size: 2rem;
    }

    @media (min-width: 1600px) {
      font-size: 2.5rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    label {
      color: #a2a2a2;
      // color: $darkgray;
      font-size: 0.875rem;
      font-weight: 400;

      @media (min-width: 1200px) {
        font-size: 1rem;
      }

      @media (min-width: 1600px) {
        font-size: 1.125rem;
      }

      &.parent {
        a {
          color: inherit;

          &:hover {
            color: $navy;
          }
        }
      }
    }
  }

  &__label-icon {
    position: absolute;
    top: 0;
    right: 0;

    svg {
      width: 90px;
      height: 40px;
    }
  }

  .btn-custom {
    @media (min-width: 1600px) {
      font-size: 1rem;
      padding: 11px 30px;
    }
  }
}
</style>
