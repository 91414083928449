<template>
    <div class="subscription-popup">
        <Popup :modal-id="modalId" :v-center="true" custom-class="subscription-news">
            <template v-slot:body>
                <div class="subscription">
                    <font-awesome-icon class="fa-plus" icon="times" data-dismiss="modal" aria-label="Close" />
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="recht-image">
                                <img class="recht-police" src="@/assets/images/newsletter-popup.svg" alt="image" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="recht-subscription">
                                <h2 class="recht-notice">{{ notice.title }}</h2>
                                <p class="recht-paragraph">{{ notice.paragraph }}</p>
                                <p class="recht-label">{{ notice.label }}</p>
                                <a class="recht-email" href="mailto:">{{ notice.email }}</a>
                                <div class="recht-action" >
                                    <Button
                                        v-if="!subscribed"
                                        class="btn-custom btn-slateblue"
                                        :label="labels.confirm"
                                        border-radius="0px"
                                        padding="11px 30px"
                                        font-size="15px"
                                        font-weight="600"
                                        @click.native="subscribed = !subscribed"
                                    />
                                    <small class="recht-note" :class="{ 'mt-0': subscribed }">{{ notice.note }}</small>
                                    <div v-if="subscribed" class="recht-note subscribed">{{ notice.subscribed }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {mapGetters} from 'vuex';

library.add(faTimes);

export default {
    components: { Popup, Button },
    props: {
        modalId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            subscribed: false,
            notice: {
                title: "Immer einen Schritt voraus!",
                paragraph:"Hier ist dein NEWSLETTER! Bekomme als Erster aktuelle Klausurentipps und Benachrichtigungen für weitere Module und Features.",
                label: "Du abonnierst unseren Newsletter:",
                email: "example@gmail.com",
                note: "Wir werden deine e-Mail-Adresse niemals verschenken, handeln oder verkaufen.",
                subscribed: 'Du hast unseren Newsletter fast abonniert, nur noch 1 Schritt. Bitte überprüfe deine E-Mails um weitere Anweisungen zu erhalten.',
            },
        };
    },
    methods: {
        subscribe() {
            this.subscribed = true;
        }
    },
    computed: {
        ...mapGetters('helper', ['labels']),
    }
};
</script>

<style lang="scss" scoped>

.subscription-news {
    ::v-deep {
        .modal-body {
            width: auto;
            height: auto;
            padding: 15px;
            color: $black;
            @include transition--ease-out;

            .subscription {
                .fa-plus {
                    width: 20px;
                    padding: 3px;
                    height: 20px;
                    cursor: pointer;
                    position: absolute;
                    border-radius: 50%;
                    background: $background;
                    right: 15px;
                    z-index: 999;
                }
                .recht-image {
                    .recht-police {
                        height: auto;
                        object-fit: contain;
                        object-position: center;
                        width: calc(100% - 20px);
                        float: right;
                    }
                }
                .recht-subscription {
                    .recht-notice {
                        font-size: 2rem;
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                    }
                    .recht-paragraph {
                        font-size: 1rem;
                        line-height: 1.5;
                        margin-bottom: 1rem;

                        @media (min-width: 1200px) {
                            font-size: 1.125rem;
                        }
                    }

                    .recht-label {
                        margin: 0px;
                    }

                    .recht-email {
                        font-weight: 600;
                        font-size: 0.75rem;
                        text-decoration: none;
                    }

                    .recht-action {
                        margin-top: 1rem;
                        text-align: center;
                        .btn-custom {
                            width: 100%;
                            text-transform: uppercase;
                            border: 2px solid $slateblue;
                            box-shadow: 0px 10px 30px rgb(98 88 241 / 37%);

                            &:hover,
                            &:focus {
                                color: $slateblue !important;
                                background: transparent !important;
                            }
                        }

                        .recht-note {
                            display: inline-block;
                            font-weight: 400;
                            font-size: 0.75rem;
                            margin-top: 15px;
                            color: $darkgray;

                            &.subscribed {
                                font-size: 0.875rem;
                                color: $saturated_green;
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }

        .modal-content {
            border-radius: 0.3rem;
        }
        .modal-header,
        .modal-footer {
            display: none;
        }
    }
}
</style>
