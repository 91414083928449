<template>
  <div class="post-card container-fluid">
    <div class="row py-4 px-3">
      <div class="col-auto">
        <img
          class="profile"
          :src="media.img"
          height="48"
          width="48"
          alt="image"
        />
      </div>
      <div class="col p-0">
        <div class="d-flex align-items-center justify-content-between">
          <div class="col p-0">
            <h6 class="name">{{ media.name }}</h6>
          </div>
          <div class="icon">
            <a
              v-if="media.link.includes('blob\:htt')"
              :href="media.link"
              target="_blank"
            >
              <font-awesome-icon
                class="fa-link fa-sm"
                icon="custom-external-link"
              />
            </a>
            <router-link :to="media.link" target="_blank">
              <font-awesome-icon
                class="fa-link fa-sm"
                icon="custom-external-link"
              />
            </router-link>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="col p-0">
            <span class="name">{{ media.label }}</span>
            <span class="title ml-2">{{ media.title }}</span>
          </div>
          <div class="icon">
            <font-awesome-icon
              class="fa-times fa-lg"
              icon="times"
              @click="media.action"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { customExternalLink } from "@/assets/icons";
library.add(faTimes, customExternalLink);

export default {
  name: "MediaPostCard",
  props: {
    media: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.post-card {
  background-color: $white;
  min-width: 205px;
  border-bottom: 1px solid $light_gray;
  .profile {
    border-radius: 50%;
  }
  .col {
    width: auto;
    max-width: 100%;
    word-break: break-word;
    .fa-times {
      color: $sienna;
      margin-right: 0.5px;
      background: lighten($danger, 20);
      padding: 2px;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
    .fa-link {
      color: $slateblue;
      padding: 2px;
      background: lighten($slateblue, 30);
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 237px) {
  .pl-3,
  .px-3 {
    padding-left: 0rem !important;
  }
  .pr-3,
  .px-3 {
    padding-right: 0rem !important;
  }
  .icon {
    padding-right: 15px;
  }
}
</style>
