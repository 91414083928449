<template>
    <SubscriptionCard class="info">
        <div class="price">€{{ price }} / {{ period }}</div>
        <p class="details">
            {{ details }}
        </p>
    </SubscriptionCard>
</template>

<script>
import SubscriptionCard from "@/components/Subscription/SubscriptionCard";

export default {
    name: "SubscriptionPriceNote",
    components: {
        SubscriptionCard,
    },
    props: {
        price: {
            type: Number,
            default: null,
        },
        period: {
            type: String,
            default: "Month",
        },
        details: {
            type: String,
            default:
                "Hinweis: Die Leistung ist gemäß § 19 Abs. 1 UStG umsatzsteuerfrei.",
        },
    },
};
</script>

<style lang='scss' scoped>

.info {
    .price {
        font-weight: bold;
        color: #6258f1;
        text-align: center;
        width: 100%;
        font-size: 24px;
    }

    p {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}

</style>