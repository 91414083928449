<template>
    <BaseCard padding="0px" margin="30px">
        <div v-for="(item, index) in cards" :key="index">
            <MediaPostCard :media="item"/>
        </div>
        <slot>
            <div class="button">
                <input
                    ref="fileUpload"
                    type="file"
                    accept=".mp3,.wav,.aac,.ogg,.wma,.flac,.alac,.aiff"
                    @change="Files($event)"
                    multiple
                    hidden
                />
                <Button
                    class="btn-slateblue"
                    :label="label"
                    padding="11px 20px"
                    icon="custom-upload"
                    icon-position="right"
                    @click="fileUpload"
                >
                </Button>        
            </div>
        </slot>
    </BaseCard>
</template>

<script>
import BaseCard from '@/components/Cards/BaseCard';
import MediaPostCard from '@/components/Media/MediaPostCard';
import Button from "@/components/Buttons/Button";

import { library } from '@fortawesome/fontawesome-svg-core';
import { customUpload } from '@/assets/icons';
library.add( customUpload );


export default {
    name: "MediaPostList",
    components: {
        BaseCard,
        MediaPostCard,
        Button,
    },
    props: {
        cards: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ''
        }
    },
    methods: {
        fileUpload() {
            this.$refs.fileUpload.click();
        },
        Files(event){
            let audios = {
                localUrls: [],
                audios: []
            };

            [...event.target.files].forEach(item => {
                if (item.type === 'audio/mpeg') {
                    audios.localUrls.push(URL.createObjectURL(item));
                    audios.audios.push(item);
                }
            });

            this.$emit( 'addAudios', audios, 'audio' );
        }
    }
};
</script>

<style lang="scss" scoped>

.button {
    display: flex;
    justify-content: center;
    padding: 20px 0 30px 0;
}
</style>
