<template>
    <div v-if="error" class="alert" :class="variant">
        <div class="message" >
            <template v-if="variant === 'register-error'">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">
                        <font-awesome-icon icon="exclamation-circle"/>
                        {{error}}
                    </li>
                </ul>
            </template>
            <p v-else>
                {{errors[0]}}
            </p>
        </div>
    </div>
</template>

<script>
import {library} from "@fortawesome/fontawesome-svg-core";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons"
library.add(faExclamationCircle)

export default {
    name: 'Notifications',
    props: {
        variant: {
            type: String,
            default: '',
        },
        error: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: () => []
        },
    },
}
</script>

<style lang="scss" scoped>
.alert {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 30px !important;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 0;
    display: flex;

    &.register-error {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-size: 16px;
                font-family: 'Arial', sans-serif;
                font-weight: 400;
                line-height: 1.5;
                text-align: left;
            }
        }
    }

    &.login-error {
        margin: 0;
        font-family: 'Arial', sans-serif;

        a {
            word-break: break-word;
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
        }
    }

    &.checkout-error {
        border-color: rgb(255, 206, 201) !important;
        background: rgb(255,239,238) !important;
        padding: auto;
        font-size: 14px !important;
        border: 1px solid;
        margin-bottom: 15px !important;
        margin: 0;
        
    }
}
</style>