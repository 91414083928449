<template>
  <div class="wrapper" ref="">
    <div class="subscription-overlay" :class="{ sticky: sticky }" ref="overlay">
      <div class="subscription-banner" ref="banner">
        <div class="images">
          <img :src="require('@/assets/police.svg')" alt="" />
        </div>
        <div class="access-content">
          <div class="title">
            VOLLZUGRIFF
            <br />
            SICHERN
          </div>
          <button class="btn-custom" data-toggle="modal" data-target="#">
            Klick hier
          </button>
        </div>
      </div>
    </div>
    <div class="locked-text" ref="locked">
      1. Sxxxxx xxx Gxxxxäxx xxx P1 xxx P2 xxxxx Vxxxxxxxxxxxxx xxx? Dxx
      Gxxxxäxx xxx Pxxxxxxxxx P1 xxx P2 xxx xxx B xxxxxx xxxxx Vxxxxxxxxxxxxx
      xxx, xxxx xxx Vxxxxxxxxxxxxxx xxx § 35 Sxxx 1 VxVxG NRW xxxxxxxxx.
      KLAUSURHINWEIS:Lxxx Bxxxxxxxxxxxxxxxx xxxx xxx xxxxxxxxx Lxxxxx- xxx
      Bxxxxxxxxxx xxxxxxxxx. Dx Pxxxxxxxxx xxx Lxxxxxxxxxxx xäxxx xxxxxx, xxxxx
      xxxx xxxxxxxxxßxxxx xxx xxxxxxxxxxxxxxxx Vxxxxxxxxxxxxxxxxxxxxxxxxxx xx
      Bxxxxxxx. Ix Fxxxxxxxx xxxßx xx xxxxx xxxxxxxxx „VxVxG“. Dxx Mxßxxxxx
      xxxxxx xxxxx Vxxxxxxxxxxxxx xxxäß § 35 Sxxx 1 VxVxG xxx, xxxx xx xxxx xx
      xxxx Mxßxxxxx xxxxx Bxxöxxx xxx xxx Gxxxxx xxx öxxxxxxxxxxx Rxxxxxx xxx
      Rxxxxxxxxxxxxxx xxx Axßxxxxxxxxx xxxxxxx xxx xxxxx Mxßxxxxx xx Exxxxxxxxx
      xxxxxx. Uxxxxxxxxxx xxx xxx Bxxxxxxxxxx xxx Mxßxxxxx. Sxx xxxx xxxx xöxxxx
      xxxxxxxxxx xxxxxxxxxx xxxxxx, xxx xxxx xxxxx xxx Vxxxxxxxxxxxxx, xxxx xxx
      Vxxxxxxxxxxxxxx xxx § 35 Sxxx 1 VxVxG xxxxxxxxx. Mxßxxxxxxx xxx xxxxx xxx
      xxxxxxxxxx xxxxxxxxx–xxxxxxxxxx Bxxxxxx xxx Vxxxxxxxxxxxxxxx. Exx
      Vxxxxxxxxxxxxx xxxxx xxxxx xxxx xxxxxxxxxxx Mxßxxxxx xxxxx Bxxöxxx xxxxxx.
      Dxx Bxxöxxx xxx xx § 1 Axx. 4 VxVxG NRW xxxxx xxxxxxxxx. Hxxxxxxxx xxx
      xxxx Sxxxxx, xxx Axxxxxxx xxx öxxxxxxxxxxx Vxxxxxxxxx xxxxxxxxx, xx
      xxxxxxxxx. Dxx Hxxxxxxxxxxxxx xxx Mxßxxxxx xxxx xx xxxxxx Zxxxxxxxxxxx
      xxxxxxxxxxx xxxxx xxxx Täxxxxxxx, xxxxx xxxxxxxxxxxxxxx xxxxx xxxx
      Exxxxxxxxx xx xxx xxxxxxxxxxx Vxxxxxxxxxxxxxxxxx, xxxxxxxx (Mxxxxx,
      Axxxxxxxxxx Vxxxxxxxxxxxxxxx, § 9 Rx. 22). Dxx Hxxxxxxxxxxxxx äxßxxx xxxx
      xxxxx, xxxx xxxxxxxxxx xxxxxxxxxxxx xxx xxxxxxxxxxx Mxßxxxxx xxxx
      xxxxxxxxx Bxxxxxxx xxx Vxxxxxxxxx xxxxxxx. Dxxxx Bxxxxxxx äxßxxx xxxx
      xxxxxxx, xxxx xxx Axxxxxxxxx xxx Mxßxxxxx xxxxx Hxxxxxxxxxxxxxxxx xxxxxx
      xxxxx xxxxxxxx xxxx. Vxxxxxxxx xxxx xxxxxxxx xxx xxxxxxxxx xxxxxxxäxxx
      Hxxxxxx. Hxxx xxxxxxx P1 xxx P2 xxx Pxxxxxxxxx, xüxxxx xxx
      Gxxäxxxxxxxxxxxxxx xxxxx xxx xxxxxx xxx B xxx xxx Wxxxxx, xxxx xxx xxx xx
      Axxx xxxxxxxx xxxxx, xxx, xxxx xxx xxxxxxxxx Axxxxx xxxxxxxxxxxxxxxxx
      Mxßxxxxxx xxxxxxxxx xxxxxx xöxxxx. Dxx Hxxxxxxxxxxxxx xxx Mxßxxxxx xxx
      xxxxxxx xx xxxxxxx. Zxxxx xxxxxx xxxx xxx xxx Bxxxxxx „xxxxxxxxxx“ xxxx
      Exxxxxxxxxxxx xxx xxxxx xxxx xxxxx Axxxxxxxxx xx xxxxx üxxxxxxxxxxxxxxxx
      Wxxxxxxxxxxäxxxx, xxx xxx xxxxxxxxxxxxxx xxx xxxxx Vxxxxxx xxxxäxx. Hxxx
      xxx xxx Gxxäxxxxxxxxxxxxxx xxxxxxxxx xxxxxxxxx, xx xxx Pxxxxxxxxx P1 xxx
      P2 xxxxxx xx Rxxxxxxx xxx Büxxxxx xxx xxx B xxxxxxxx xxx xxx xxxxxxxxxx
      Gxxxxxxxxxxx xxxxxxxxx. Wxxxxxxxx xüxxxx xxxx Rxxxxxxx xxx xxx Gxxxxx xxx
      öxxxxxxxxxxx Rxxxxx xxxxxxxxx. Exxx öxxxxxxxxxx Sxxxxxxxxxxx xxxxx xxxx
      xxx xxxxxxxxxxxxx Sxxxxxxxxxxxxxx xxx, xxxx xxx Hxxxxxxxxäxxx xxx Sxxxxxx
      xxxxxxxx xxxxx Rxxxxxxxxx xxxxxx xxxx xxxxxxxxxxxx xxxx. Hxxx xxxx xxx
      Pxxxxxxxxx P1 xxx P2 xxxäß § 8 PxxG NRW xxxxxx, xxx B xxxxxxxxxxxx.
      KLAUSURHINWEIS:Axx xxx Rxxxxxäßxxxxxx xxxxx xx xxxx xxxxx xx. Dxx Pxüxxxx
      xxxxxxxxxxx xxxxxxxxx xxx Fxxxx, xx xxx Hxxxxxxx xxx P1 xxx P2 xxxxx
      Vxxxxxxxxxxxxx xxxxxxxxxx. Ox xxx Bxxxxxxxxxxx xxxxxxxxxxxxxx xxxüxxx xxx,
      xxx xxxxxxxxxxx. Ixx § 8 PxxG NRW xxxxx xxxüxxx, xäxx xxx xxx. xxxxxxxxxxx
      Vxxxxxxxxxxxxx xxxx xxxxxxxxxxxx, xxxx xx xäxx xxxxxxx xxx Vxxxxxxxxxxxxx.
      Wxxxxxxxx xxxx xxx Mxßxxxxx xxxxx Rxxxxxxxxxxxxxx xxxxxxxxx. Exxx Rxxxxxxx
      xxxxx xxx, xxxx xxx Mxßxxxxx xxx Bxxöxxx xxxxxx xxxxxxxxx xxx, xxxx
      xxxxxxxxxxxx Rxxxxxxxxxx xx xxxxxx. Rxxxxx xxx Bxxxxxxxxxx xüxxxx
      xxxxxxxxxxx xxxxüxxxx, xxäxxxxx, xxxxxxxxxx, xxx xxxxxxxxx Wxxxxxx
      xxxxxxxxxxxx xxxx xxxxxxxx xxxxxx. Fxxxxxxx xxx xxxx, xx xxxx Rxxxxxxx
      xxxxxxx xxx P1 xxx P2 xxxxxxx xxx. Dxxüx xxxxxxx, xxxx xxx B xxxxäxx
      xxxxx, xxxx xxx xxx xx Axxx xxxxxxxx xxxxx xxx xxxxx xxx Axxxxxxx xxxxxxxx
      xxxxx, xxxx xxx Vxxxxxxxx xxx B, xxx Mxxxxxxäxxxxxx Fxßxxxx xx xxxxxxx,
      xxxxx xxxxxxxxxx xxx. Hxxxxxxxx xxxxxxx xxxxxx, xxxx xxxx xxxxx
      xxxxxxxxxxx xxxxxxxx. Dxx B xxxxx xxxxxxxxxx xxxxxxxxxx, xxxx xxxx
      xxxxxöxxxxxx xxx xxx xxx xxx xx Axxx xxxxxxx. Exx Gxxxx xxxx xxx Vxxxxx
      xxxxxx xxxxx xxxxxxxxxxxxx. Hxxxxxx xxxxxxx xx xxxx xxxxxxxx xx xxxx
      xxxxxxxxxx Gxxäxxxxxxxxxxxxxx. „Dxx xxxxxxxxxxxx Gxxäxxxxxxxxxxxxxx
      xxxxäxx xx Axxxxxxxxxx xxxxx üxxx xxxx Wxxxxxx xxx Hxxxxxxx xxxxxxxxxxxxx
      Rxxxxxxxxxxxxxxx. Sxx xxx xxx Zxxx, xxx xxx Wxxxxxxxxxxxxxxxßxxx xxx
      Bxxxxxxxxxx xxxxxxxxxxx. Exx xxxxxxxxxx Vxxxxxxxx xxxx xxx xxxxxx xxxx
      xxxxx xxx xxx xxxxäxx xxxxxxxx xxxxx xxxxxxxxxxxx Rxxxxxxx. Sxxxx xxxxxx
      xxx xxxxxxxxxxxx Gxxäxxxxxxxxxxxxxx xxxxxx Vxxxxxxxxxxxxx xx Sxxxx xxx
      Axx. 35 Sxxx 1 (…) VxVxG xxx, xxxxxxx xxx xxx xxxxxxxx-xxxxxxxxxxxx
      Hxxxxxx xx xxxxxxxxxxxxx (VG Müxxxxx, Uxxxxx xxx 18.02.2020 – M 7 K
      18.5065 x. x. N.). Rxxxxx xxx B xxxxx xxxx xxxxx xxxxxxxxxxx xxxxxxxxx.
      Exxx Rxxxxxxx xxxxx xxxxxxxx xxxxx xxx. Dxx Gxxxxäxx xxx P1 xxx P2 xxxxxx
      xxxxxxxx xxxxxx Vxxxxxxxxxxxxx xxx.
      KLAUSURHINWEIS:Rxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxx xx xxxxx Kxxxxxx xxx.
      Hxxxxxx: Ex xxx xxxx xxxäxxxx, xxx Pxüxxxxxxxxxxx „Axßxxxxxxxxx“ xxx
      „Exxxxxxxxx“ xxxxxxxxxxxx. Bxxxx Mxxxxxxx xxxx xx xxxxxxx. Exxx
      Axßxxxxxxxxx xxxxx xxx, xxxx xxx xxxöxxxxxxx xxxxxxxxxxx Mxßxxxxx xxxx xx
      xxxxx Axxxxxxxxx xxßxxxxxx xxx Bxxöxxx xxxxxxx. Dxxx xxx xxxx xxxxxxxxxxx
      xxx Fxxx. Exxx Exxxxxxxxxxxxxxxx (xxxxxxx-xxxxxxxxxxx) xxxxxx xxx
      Vxxxxxxxxxxxxx xxxxxx xxx xxx Axxxxxxxxxxxxüxxxx xxx xxxx xxx xxxxx
      Rxxxxxxxxx xx. Hxxx xxxxxxxx xxx Pxxxxxxxxx xxxxxxxxxßxxxx xxx B xxx
      Exxxxxxxxxxx, xxxxx xxxxxxxxxxx, xx. Zxxxx xxxxxxxx xxx Pxxxxxxxxx xxx B
      xxxxxxx xxx xxx Vxxxxxx xx 23.10.2020 xx. Exxx Exxxxxxxxxxxxxxxx xxx xxxxx
      xxxxxxx. 2. Sxxxxx xxx Gxxxxäxx xxxx xxxxxxxxxxxxxxxxxx xxxxxxxxx Mxßxxxxx
      xxx? Dxx Mxßxxxxx xxx xxxxxxxxxxxxxxxxxx xxxxxxxx, xxxx xxx – xxxxxxxx xxx
      xxxx xx Rxxxxxxxxxxxxxxxxxx xxxxxxxxxxx Gxxxxxxxxxx xxx Vxxxxxxxxxx xxx
      Gxxxxxxx – xxxxx Pxxxxxxxxxxxxxxxxx xxx Exxäxxxxxxxxxxxxxxxxxx xxxxxx. x)
      Rxxxxxxxxxxxxxxxxxx xxx Vxxxxxxxx xxx Gxxxxxxx xxx Pxüxxxxxxxßxxxx Dxx
      Vxxxxxxxxx xxx xx xxx Rxxxx xxx xxxxxxx Gxxxxx xx Rxxxxx xxxxx Hxxxxxxx
      xxxxxxxx. Dxxx xxxxxx xxxx xxx xxx Rxxxxxxxxxxxxxxxxxx. Wxxxxxxxxxxx
      Bxxxxxxxxxx xxxxxx Rxxxxxxxxxxxxx xxx xxx Vxxxxxxxx xxx Gxxxxxxx.
      Hxxxxxxxx xxxxxxxx xxx, xxxx xxx Vxxxxxxxxx xxx xx xxx Fäxxxx xxxxxxx
      xxxx, xx xxxxx xxx xxxxxxxxx Gxxxxx xxx Bxxöxxx xxx Hxxxxxx xxxäxxxxxx.
      Dxxx xxxx xxxxxxxxxxxx xx xxx Fäxxxx xxx Exxxxxxxxxxxxxxxxxx. Dxx
      Exxxxxxxxxxxxxxxxxx xxxxxxxäxxxxxx xxxxx Rxxxxx xxx Büxxxxx. Dxxxx xöxxxx,
      xüxxxx xxxx xxxxx, xxxxxxxxxx xxxxxxxxxxxxxxxxxxx xxxx. Ox xxxx xxx Fxxx
      xxx, xxxxxx xxxx xxx xxx xxxxxxxxxxx Wxxxxxxxxxxxxxxxxxxxxx. Hxxxxxxx xxx
      xxx Gxxxxxxxxxx xxxxxxxxxxxx xxx, xx xxxxxxxxxxxxx xxxxxxxxxx Bxxxxxxxx
      xxxx xxxxxxxxxxxx Exxxxxxxxxxxxx xxxxxx xx xxxxxxx. Dxxxxx xxxxxxxx
      „xxxxxxxxxx“ xx xxxxxxxxxxxxxxxxxxxxx Bxxxxxx xx xxx Rxxxx, xxxx xxx
      Rxxxxxxxxxxxxxx xxxxxxxxxx xüx xxx Vxxxxxxxxxxxxx xxx Gxxxxxxxxxx xxxx
      xxxx. x) Exxxxxxxxxxxxxxxxx xxx Gxxäxxxxxxxxxxxxxx Fxxxxxxx xxx, xx xxx
      Axxxxxxxx xxx P1 xxx P2 xxxxxüxxx xxx B xxx xxx xxxxxxxxx Ixxxxx
      xxxxxxxxxxxxxxxxxxx xxx: „(…) xxxx xx xx xxx Vxxxxxxxxxxxx Vxxxäxxx
      xxxxxxx xxxx xxx xxxx xxx Pxxxxxx xxx B xxx Pxxxxxx xxxxxxxxxx xxx, xxx
      xxx xxxx Gxxäxxxxxxxxxxxxxx xxxxxxxxüxxxx. Axxxöxxx xxx, xxxx B xx
      23.10.2020 xxx Kxxxxxx Fxßxxxx xxxxxxxx xxxx, xxx xx xxxx xxxxxöxxxxxx xxx
      xxx xxx xxx xx Axxx xxxxxxxx xüxxx. Ex, B, xxxxx xx xxxx xxx üxxxxxxxx, xx
      xx xx Zxxxxxx xxxx xxxxxx xxx Mxxxxxxäxxxxxx Fxßxxxx xxxxxxx xxxxx.“ xx)
      Axxxxxxxxx xxxxxxxx Hxxxxxxxx-, Dxxxxxxx- xxx Uxxxxxxxxxxxxxxxxxxx xxx
      Wxxxxxx, Ixxxxxxxxxx „Mxßxxxxxxx xüx xxx Fxxxx xxx Exxxxxxxxxxxxxxäx xxx,
      xx xxx Gxxäxxxxxxxxxxxxxx xxxxxxxx xxx, xxx Wxxxxxxxxxxxxxxxßxxxxxxxxxxxx
      xxx Bxxxxxxxxxx xx xxxxxxxxxx Wxxxx xxxxxxxxxäxxxx, xxx xxx xxx
      Bxxxxxxxxxx xxx Fxxxx xxxäxxx, xxx xxxx Exxxxxxxxxxxxxxxxxxxxx xxx
      Bxxxxxxxxxx xxxx xxxxxxxxx. Kxxxxxxxx xüx xxx Bxxxxxxxx xxxx xxxüx, xx xxx
      Bxxxxxxxxx xxxxxxxxx xxx xöxxxxxx Gxxxxxxx xxx Fxxxxx xxxxx Vxxxxxxxxx
      xxxxxxxxx xxxxxxxxxxx xxxx, xxxx xxxx xxx xxxxxüxxx xxxxxxxx Mxßxxxxxx
      xxxxxxxxxxxx xxxx xxxxxxxxx xxxxxx (xx xxxxxx Fxxx xxxxxxxxx xxx xxx
      xxxxxxxxxxxxx Exxxxxxxxxxxxxxxxxxxxx), xxxx xx x. B. xxxxx Bxxxxxxxxx xxx
      xx xxx Vxxxxxxxxxxxx xxxxxxxxx, xxx xxx Lxxx xxxxxxxx, Vxxxxxxxx xxx
      xxxxxx xxxxxxxxxxxx Rxxxxxxx xxxxxxxxxxxx xxxx, xx xxxx xxxxx Txxxxxxxx xx
      xxxxx Dxxxxxxxxxxxx xx xxxxxxxxxx, xxxx xxx Sxxxxxxxx xüx xxxx xxxxxx
      Wxxxxxxxxxxxxxxxßxxx xxxx xxx Fxxxxx xxx xxxxxxxxxxxxx Mxßxxxxxx xxx
      Nxxxxxxxxx xx xxxxx xxxxxxxxxxx xxxx, xxxx xxxxx Exxxxxxxxßxxxxxxxxxxxx
      xxxx xxxxxxxxx xxx (Exxxxxxxxxxxxxxäx). Mxßxxxxxxx xüx xxx Axxxxxxxxx xxxx
      xxxx xxx äxßxxx Fxxx xxx Gxxäxxxxxxxxxxxxxx, xxx Bxxxüxxxxx, xxx
      Bxxüxxxxxxxxxxxx xxxxx xxxxxxxxx xxx. xxxxxxxxxxx Uxxxäxxx, Txxx xxx
      Gxxxxxx, xxxx xxxxxxxxx Axxxxxxxx xxxxx Bxxüxxxxxxxxxxxx xxx
      Exxxäxxxxxxxxxxxxxx xxxxx Zxxxxxxxxxxx xxx Zxxxx xxx Mxßxxxxx.“ (VG Köxx,
      Uxxxxx xxx 20.11.2014 – 20 K 2466/12) xx) Wxxxxxx xxx xxxxxxxäxxxxxxxx
      Hxxxxxxxxx xxx P1 xxx P2 Ex xxxxx xxxx xxx Exxxxxxx xx xxx xxxxxxxxxxx
      Pxxxöxxxxxxxxxxxxxxx, xxxxxxxxxxxx xxx Rxxxx xxx xxxxxxxxxxxxxxx
      Sxxxxxxxxxxxxxxx xxxäß Axx. 2 Axx. 1 x. V. x. Axx. 1 Axx. 1 GG xx
      Bxxxxxxx. Dxx Rxxxx xxx xxxxxxxxxxxxxxx Sxxxxxxxxxxxxxxx xxxäxxxxxxxxx,
      xxxxxx üxxx Pxxxxxxxx xxx Vxxxxxxxxx xxxxxxxxxxxxxxxxx Dxxxx xx
      xxxxxxxxxxx. Dxxxxx xxxxx xxxx BVxxxG „xxx xxx xxx Gxxxxxxx xxx
      Sxxxxxxxxxxxxxxx xxxxxxxx Bxxxxxxx xxx Exxxxxxxx, xxxxxxäxxxxxx xxxxxx xx
      xxxxxxxxxxx, xxxx xxx xxxxxxxxx xxxxxxx Gxxxxxx xxxxöxxxxxx
      Lxxxxxxxxxxxxxxxxx xxxxxxxxx xxxxxx“. Hxxx xxxxx xxx Pxxxxxxxxx
      xxxxxxxxxxxx, xxxx xxxx xxx B xxx xxx xxxxxx xxx xxxxxx Axxxxxxäxxx, xxxx
      xxx Fxßxxxxxxxxxxx, xxxxxxxx. Axßxxxxx xxxxx xxx B xxxxxxxxxx, xxxx xx
      xxxxxxxxxxx xxxxxxx xxxxx. Zxxx xxxxxxx xxxxx xxxxxxxxxxx Ixxxxxxxxxxxx
      xxx Gxxxx xüx xxx Gxxäxxxxxxxxxxxxxx xxx. Dxx Gxxäxxxxxxxxxxxxxx xxxxxx
      xxxüxxx xxx Sxxxxxxxxxxxx xxx Rxxxxx xxx xxxxxxxxxxxxxxx Sxxxxxxxxxxxxxxx
      xxxxx. Wxxxxx xxxx xxx Exxxxxxx xx xxx xxxxxxxxxx Hxxxxxxxxxxxxxxxx xxxäß
      Axx. 2 Axx. 1 GG xxxxxxxxx. Axxxxxxx xxx xxxxxx xxx xxxxxxxxxxx
      Sxxxxxxxxxxxxxx xxxxx xxx xxxxxxxxxx Hxxxxxxxxxxxxxxxx xxx Axx. 2 Axx. 1
      GG xx xxxxx Gxxxxxxxxxxxxüxxxx xxxxxx xxxxxxxxxx Fxxxxxxxxxxxxxxx xxxüxx,
      xxxxxxxxxx Sxxxxxxxxxxäx xxx xxxxxxxxxxx Hxxxxxxxxxxxxxxxx. Dxxxx
      Sxxxxxxxxxxäx xxxxxx xxxx xxxxxx xxxxx, xx xxx Sxxxxxxxxxxxx xxx Rxxxxx
      xxx xxxxxxxxxxxxxxx Sxxxxxxxxxxxxxxx xxxxx xxöxxxxx xxx. Sxxx xxx Zxxxx
      xxx Axxxxxxxx xxxxx P1 xxx P2 xxx xx, xxx B xxxxx xx xxxxxxx, xxxx xxxxxx
      xx xx Zxxxxxx xxxxxxäxxx, xxx Mxxxxxxäxxxxxx, xxx. Kxxxxxx, Fxßxxxx xx
      xxxxxxx. Axxxxx xxxxxx Uxxxxxx xxxx xxxxxxx Exxxxxxx xxx xxx
      Wxxxxxxxxxxxxx xxx B xxxxxx. Dxxx xxxx xxxxxxxxxxxx xxxxxxxx xxx Axxxxxx,
      xxxx B xx xxxx xxx üxxxxxxxx xxxx, xx xx xx Zxxxxxx xxxx xxxxxx xxx
      Mxxxxxxäxxxxxx Fxßxxxx xxxxxxx xxxxx. Dxxxx xxxxx xxxxxüxxx xxx B xxx
      Axxxxxxx xxxxxxxx, xxxx xxxxxxxxxxxx Mxßxxxxxx xxxxx xxx xxxxxxxxx xxxxxx,
      xxxxxx xx xxxxxxxxxx xxx Mxxxxxxäxxxxxx Fxßxxxx xxxxxxx. Ex xxxxx xxxxxxxx
      xxx Exxxxxxx xx xxx xxxxxxxxxx Hxxxxxxxxxxxxxxxx xxxäß Axx. 2 Axx. 1 GG
      xxx. Hxxxxxx xxxxx, xxxx xxx Gxxäxxxxxxxxxxxxxx xxxxx Exxxxxxx xx xxx
      xxxxxxxxxx Hxxxxxxxxxxxxxxxx xxxäß Axx. 2 Axx. 1 GG xxxxxxxxx.
      KLAUSURHINWEIS: Ex xxx xxxxx xxxxxxxx xxx Exxxxxxx xxx xxxxx Vxxxxxxxxx xx
      xxxxxxxxxxxxxx. Exx Exxxxxxx xxx xxxxxxxxxxxxxxx. Ox xxx Mxßxxxxx
      xxxxxxxxxxxxxxxxxxxx xxxxxxxxxxxxxx xxx, xxx xxxx Fxxxx, xxxxxx xx Rxxxxx
      xxx Pxüxxxx, xx xxxx Vxxxxxxxxx xxx Gxxxxxxxxxxx xxxxxxxx, xxxxxxxxxxx
      xxxx. Hxxx xxxxx xx xxx xxxxxx xx, xxxxx xxxxxxxxxxxxxxxxxxxxx Exxxxxxx xx
      xxxxxxx, xxxxx xxxxxxxx xxx Wxxxxxxxxxxxxxxxxxxxxx xx xxx Exxxxxxx
      xxxxxxxx xxxx, xxxx xxxx Exxäxxxxxxxxxxxxxxxxxx, xxxxxx xxx
      Pxxxxxxxxxxxxxxxxxx xxxxxxxxxx, xxxxxxxxxxxx xxx. Dxx Exxxxxxxxxxxxxxäx
      xxxx xxxxxxxxxxxx xxxx xxxxxxxx xxxxxx. Hxxxxx xäxx xxxx xxxxxxüxxxx,
      xxxxx xxxx xxxxx xxxxxxxxxxx xxx xxx Exxxxxxxxxxxxxxxxxxxx xxx B
      xxxxxxxxxx xxxxx. Dxxxx xüxxxx xxxxxxüxxx xxxxxx, xxxx xxx Sxxx „Ex, B,
      xxxxx xx xxxx xxx üxxxxxxxx, xx xx xx Zxxxxxx xxxx xxxxxx xxx
      Mxxxxxxäxxxxxx Fxßxxxx xxxxxxx xxxxx.“ xxxxxxxxx xxxx xx xxxxxxxxxxxxx
      Mxßxxxxxx xüxxxx xxxxxx, xxxx B xxxx xxxxäxxxxxx xxxxxxxxxxxxxx xxxx
      xxxxxxxxxxxxxxxx xxxxxxxx xxxxäxx. Ix Rxxxxx xxx Bxxxxxxxxxxx xxx xxxxxx
      Fxxxx xxxxx xxx Rxxxxxxx x. S. x. § 35 Sxxx 1 VxVxG xxxxxxxx, xxxx – xx
      xxx Bxxxxxxxxxxx xxx xxxxxxx Fxxxx – xxxx xxx xxxxxxxxxxxxxx xxxxxxxxxx
      Exxxxxxx xxxxxxxxxx xxxxxx. Bxxxxx xxx xxx Axxxxxxx xxxxx Vxxxxxxxxxxxxxx.
      Jxxxxxxxxx xxx xxxx xxxx xx xxxxxxxxxxxxxx. Exxx Rxxxxxxx xxxxx xxx xxx,
      xxxx xxxx xxxxxxxxxxxx Rxxxxxxxxxx xxxxxxx xxxx. Ix Gxxxxxxxxxx xxxx xxx
      Exxxxxxx xxxxxxxxx, xxxx xxx Wxxxxxxxxxxxxxxxßxxx xxxxxxxxxäxxx xxx. Ex
      xxxxxx xxxx xxxxxxxxx „xxxxxxx“ xüx xxxxx Gxxxxxxxxxxxxxxxxxx. 3. Wxx xxx
      Füxxxx xxx Gxxxxäxxx xxxxxxäßxx? KLAUSURHINWEIS:Ix Rxxxxx xxx Bxxxxxxxxxxx
      xxx Fxxxx 1 xxxxx xxxxxxxxxxxx, xxxx xxxx xxxx Vxxxxxxxxxxxxx xx Sxxxx xxx
      § 35 Sxxx 1 VxVxG xxxxxxxx. Dxx Vxxxxxxxxxxxxx xxx xxxx xxx
      „Sxxxxxxxxxxxxx“ xxx Vxxxxxxxxx, xxxx xxxxxxxxxxx xxx xxxxxxx
      Hxxxxxxxxxöxxxxxxxxx. Ox xxx Mxßxxxxx xxxxxxäßxx xxx, xxx xxxxx xxxxxxxxxx
      xxxxxäxxxx xxx xxx Pxüxxxx, xx xxx Vxxxxxxxxxxxxx xxxxxxxx. Axxxxx
      xxßxxxxxxx xxx – xxxxxxxx xxx xxxxxxxäxxxxx Gxxxxxxxxxx xxx Vxxxxxxxxxx
      xxx Gxxxxxxx – xxxx xxx xxxxxxxxxxxxx Exxäxxxxxxxxxxxxxxxxxx xxxüxxx xxx.
      I. Vxxüxxxxxxxxx: Gxxxxxxxxxxxxxxxxxx Exx Gxxxxxxxxxxxxxxxxxx xx xxx
      xxxxxxxxxx Hxxxxxxxxxxxxxxxx xxxäß Axx. 2 Axx. 1 GG xxxxx xxxxxxx
      xxxxxxxxxxxx. (x. x.) KLAUSURHINWEIS:Sxxxxxxx xx xx Rxxxxx xxx
      Bxxxxxxxxxxx xxx Fxxxx 2 xx xxx Exxxxxxx xxxxxxxx xxxx, xxxx xxxx Exxxxxxx
      xxxxxxxx, xx xxx xxxx xxx Bxxxxxxxxxxxxxxxx xxxx xxx Exxxxxxxxxxxxxxxxx
      xxx Mxßxxxxx xx xxxxxxxxxxxx. II. Exxäxxxxxxxxxxxxxxxxxx Axx
      Exxäxxxxxxxxxxxxxxxxxx xxxxx xxxx xxx xxxxxxxxxxxxxxxxx Gxxxxxxxxxxxxx
      xxxäß § 8 Axx. 1 PxxG NRW xx Bxxxxxxx. 1. Vxxxxüxxxx Exxx
      xxxxxxxxxxxxxxxxxx Exxäxxxxxxxxxxxxxxxxxx, xxxx. xxx xxx
      Vxxxxxxxxxxxxxxxxx xxx Bxxxxx, xxx xxxxx xxxxxxxxxxx. Exxxxxxxx xxxx xxxxx
      xxxxxxxäxxxxx xxxxxxxxxxxxxxxxxx Sxxxxxxxxxßxxxxxx xxxäß xxx §§ 9 xx. PxxG
      NRW xxxxxxxxxxx. Dxx Gxxxxxxxxxxxxx xxxäß § 8 Axx. 1 PxxG xxxx xxxxxxxx
      xxxxüxx xxxxxx. 2. Pxüxxxx xxx § 8 Axx. 1 PxxG NRW (Gxxxxxxxxxxxxx xxx
      Gxxxxxxxxxxxxx) § 8 Axx. 1 PxxG xxx xxxüxxx, xxxx xxx Mxßxxxxx xxxxxxx–
      xxx xxxxxxxxx-xxxxxxxxx xxxxxxäßxx xxx. x) Fxxxxxxx Rxxxxxäßxxxxxx Dxx
      Mxßxxxxx xxx xxxxxxx xxxxxxäßxx, xxxx xxx xxxxäxxxxx Bxxöxxx
      xxxxxxxxxxxxxxxxxxxx xxx xxxxxxxäß xxxxxxxxx xxx. xx) Zxxxäxxxxxxxx P1 xxx
      P2 xüxxxxx xxxxäxxxx xxxxxxx xxxx. Sxx xxxxx xxxxäxxxx, xxxx xxx öxxxxxx
      xxx xxxxxxxx xxxxäxxxx xxxxx. Dxx Bxxöxxx xxx xxxxxxxx xxxxäxxxx, xxxx xxx
      xxxxx Gxxxxxxx xxx xx xxxxxxxxx Fxxx xxxxxxxxxxxxxxx Axxxxxxx xxx
      Bxxxxxxxxx üxxxxxxxxx xxxxxx xxxx. Hxxx xxxxxx xxxx xxx xxxxxxxxx
      Zxxxäxxxxxxxx xxx § 1 Axx. 1 Sxxx 2 PxxG NRW x.V.x. § 11 Axx. 1 Nx. 1 POG
      NRW. Dxx Gxxäxxxxxxxxxxxxxx xxxxx xxxx xxx xxxxxxxxxxxx Bxxäxxxxxx xxx
      Sxxxxxxxxx, xxxx xxx Sxxxxxxxxxxxxx. P1 xxx P2 xxxxx xxxxx xxxxxxxx
      xxxxäxxxx. P1 xxx P2 xüxxxxx xxxxx öxxxxxx xxxxäxxxx xxxxxxx xxxx. Dxx
      Bxxöxxx xxx öxxxxxx xxxxäxxxx, xxxx xxx xxxxx Gxxxxxxx xäxxxxxx xxxxäxxxx
      xxx. Hxxx xxxxxx xxxx xxx öxxxxxxx Zxxxäxxxxxxxx xxx § 7 POG NRW. P1 xxx
      P2 xxxxx xxxxx öxxxxxx xxxxäxxxx. P1 xxx P2 xxxxx xxxxx xxxxxxxxx
      xxxxäxxxx. xx) Vxxxxxxxx P1 xxx P2 xüxxxxx xäxxxxxxx xxxxxxxxxxxxxx
      Vxxxxxxxxxxxxxxxxxxxxx xxxxxxxx xxxxx. Bxxxxxxxx Vxxxxxxxxxxxxxxxxxxxxx xx
      Bxxxx xxx § 8 Axx. 1 PxxG NRW xxxxxxxx xxxxx. Fxxxxxxx xxx, xx xxx B xxxäß
      § 28 VxVxG NRW xxxxxöxx xxxxxx xxxxxx. Gxxäß § 28 Axx. 1 VxVxG xxxßx xx,
      xxxx xxxxx xxx Vxxxxxxxxxxxxx xxxxxxxx xxxx, xxx xx Rxxxxx xxxxx
      Bxxxxxxxxxx xxxxxxxxx, xxxxxx Gxxxxxxxxxx xx xxxxx xxx, xxxx xx xxx xüx
      xxx Exxxxxxxxxxx xxxxxxxxxxx Txxxxxxxx xx äxßxxx. Wxx xxxxxxx
      xxxxxxxxxxxx, xxxxx xxxx Vxxxxxxxxxxxxx xx Sxxxx xxx § 35 Sxxx 1 VxVxG
      xxx. Sxxxx xxx xxx Axxxxxxxxxxxxxxxx xxx § 28 VxVxG xxxxxxäxxxxxx
      xxxxxxxxxxxxxx.Fxxxxxxx xxx, xx xxxxxxxx xxx xxxxxxxxxxx Rxxxxxxxxxxxxxx
      xxx xxx Rxxxxxxxxxxxxxxxxxxxxxxxxxx xx xxxxxxx xxx, xxxx xxx B xxxxx,
      xxßxxxxxxxxxxxäxxxx, xxx Rxxxx xxx Sxxxxxxxxxxxx xxxxxxäxxxx xxx. Hxxxxüx
      xxxxxxx, xxxx xxx Gxxxxxxxxxxxxxxxxxx xxxxxäxxxx xxx xxx xxxäxxxxx
      Hxxxxxxxxxxxx xxx Bxxöxxx xxxxxxx. Dxxxxxx xxxxxxx xxx Wxxxxxxx xxx
      Gxxxxxxx xxx xxx Sxxx xxx Zxxxx, xxx xxxxx Vxxxxxxxxxxxxx, xxxxxxx
      xxxxxxxx xxxx xxxxxxxxxx Rxxxxxxx xxxxäxx, xxx Axxxxxxxxx xxx Rxxxx xxx
      Sxxxxxxxxxxxx xx xxxxx. Hxxx xxxxx xx xxxx xx xxxxx xxxxxxx Rxxxxxxx,
      xxxxxx xxxx Axxöxxxx xxxx xxxxx xxxxxxxäß xxxäß § 28 Axx. 1 VxVxG xx
      xxxxxxxx xxxxx. xx) Fxxx Fxxxxxxxxxxxxxxx xxxxxxxx xxxxx.
      KLAUSURHINWEIS:Dxx Vxxxxxx xxx xxx Vxxxxxxxxx üxxx Fxxxxxxxxxxx, § 37 Axx.
      2 Sxxx 1 VxVxG, xäxx xxxxxxxx, xx xxx Vxxxxxxxxx xxx xüx Vxxxxxxxxxxxxxx
      xxxx. xx) Exxxxxxx Dxx Mxßxxxxx xxx P1 xxx P2 xxx xxxxxxx xxxxxxäßxx. x)
      Mxxxxxxxxx Rxxxxxäßxxxxxx Dxx Mxßxxxxx xxx xxxxxxxxx-xxxxxxxxx xxxxxxäßxx,
      xxxx xxx Txxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxx xxx xxx Mxßxxxxx
      xxxxxxxxxxxxxxxxxxx xxx xxxxäxxxxxxäßxx xxx. xx) Gxxxxx xüx xxx
      Öxxxxxxxxxx Sxxxxxxxxx xxx Öxxxxxxxxxx Oxxxxxx Gxxäß § 8 Axx. 1 PxxG NRW
      xxx xxxx Gxxxxx xüx xxx öxxxxxxxxxx Sxxxxxxxxx xxx Oxxxxxx xxxxxxxxxxxx.
      Exxx xxxxxxxxx Kxxxxxxxxxxxxxx xxx Gxxxxxxxxxxxxxxxx xxxxxxx xxxxx. Dxxxx
      xxx xxx xxx Rxxxxxxxxxxxx xxx xxxxxxxxx Gxxxxx xxxxxxxxxx. Exxx xxxxxxxx
      Gxxxxx xxxxx xxx, xxxx xxxx Sxxxxxxx xxxx xxx Vxxxxxxxx xxx xxxxxxxxxxxxx
      Axxxxx xxx xxxxxxxx xx xxxxxxxxxxx Gxxxxxxxxx xxx xxxxxxxxxxxxx
      Wxxxxxxxxxxxxxxxxx xxx xxxxxxxxxxxxx Sxxxxxxxx xxxäxxxxx xxxx. Dxx
      xxxxxxxxxxxxx Sxxxxxxüxxx xxxx xxx öxxxxxxxxxx Sxxxxxxxxx xxx xxx
      öxxxxxxxxxx Oxxxxxx. Uxxxx xxx Bxxxxxx xxx öxxxxxxxxxxx Sxxxxxxxxx
      xxxxxxxx xxx xxx Sxxxxx xxx Ixxxxxxxxxxxxxxxxüxxx, xxx Sxxxxx xxx
      Uxxxxxxxxxxxxx xxx xxxxxxxxxx Rxxxxxxxxxxxx xxxxx xxx Sxxxxx xxx Bxxxxxxxx
      xxx xxx Vxxxxxxxxxxxxxx xxx Sxxxxxx. Uxxxx xxx öxxxxxxxxxxx Oxxxxxx xxx
      xxx Gxxxxxxxxx xxx xxxxxxxxxxxxxxx Rxxxxx xx xxxxxxxxx, xxxxx Bxxxxxxxx
      xxxx xxx xxxxxxx xxxxxxxxxxxx xxx xxx xxx Wxxxxxxxxx xxx Gxxxxxxxxxxxx xx
      xxxxxxxxxxxxxx xxxxxxxx xxx xxxxxxxxx Axxxxxxxxxxx xxx xxxxxäxxxxxxx
      Vxxxxxxxxxxxx xxxxx xxxxxxxxxx xxxxxxxxxxxx Zxxxxxxxxxxxxx xxxxxxxxx xxxxx
      xxxxxxxxxx Gxxxxxx xxxxxxxxx xxx. Hxxx xxx B xxxxxxx xxxxxxxäxxx
      xxxxxxxxxxx xx Exxxxxxxxxx xxxxxxxx xxx xxxxxxxx xxxxxxxäxxxxx Dxxxxxx
      xxxxxxxx xxxxxxxxxxx. Ex xxx xxxxxxx xxxx, xxxx xxx B xxxx Vxxxxxxxx xxxx
      Exxxxxxxxx xxxxx äxxxxx xxxx xxx xxxxx xxxxxxx Sxxxxxxxxx, xxxxxx xxx
      Sxxxxxxxx xxx öxxxxxxxxxxx Sxxxxxxxxx xxx xxxxxxxxxx, xxxxxx. Sxxxx xxx
      xxxxxxxxxxxxxx xxxx Gxxxxx xüx xxx öxxxxxxxxxx Sxxxxxxxxx xxx. Wxxxxxxxx
      xxxxx xx xxxxxxxxxßxxxx xxx xxxx xx xxxx Sxxxx xx Rxxxxx xxx Bxxxxxxxxxx
      xxxxx xxxxxxxxxxxxxxxxxxxxxxxxx Mxßxxxxx xx. Ox xxx B xxxxäxxxxxx xxxxxxxx
      xxxxxxxxxxxxxx xx Exxxxxxxxxx xxxxxxxx xäxx, xxx xxxxxxxxxxx. xx) Axxxxxxx
      B xüxxxx xxx xxxxxxxxxxx Axxxxxxx xxx Mxßxxxxx xxxx. Dxxx xxx
      xxxxxxäxxxxxx xxx Sxöxxx. Dxx Gxxxxx xxxxxxxxxxxxx xxxxxxxx xxx
      Vxxxxxxxxx- xxx xxx Zxxxxxxxxxöxxx. Dxx Zxxxxxxxxxöxxx xxxäß § 5 Axx. 1
      PxxG NRW xxx, xxx xx Zxxxxxxxx xxx Gxxxxxxxxxxxxxxxßxxxxx xxx Ixxxxxx xxx
      xxxxäxxxxxxxx Gxxxxx üxxx xxx Sxxxx xxx, xxx xxx xxx Gxxxxx xxxxxxx. Dxxx
      xxx xxxx xxxxx xxxxxxxäxxx. Dxx Vxxxxxxxxxxxöxxx xx Sxxxx xxx § 4 Axx. 1
      PxxG NRW – xxxx xxxx Hxxxxxxxxxxöxxx xxxxxxx – xxxxxxx xxxxx xxxx
      xxxxöxxxxxxx Vxxxxxxxx xxxx Gxxxxx. Hxxx xxxxxxx xxx B xxx Gxxxxx xxxxx
      xxxx Vxxxxxxxx, xxxxx xx xxx Mxxxxxxäxxxxxx Fxßxxxx xxxxxxx. B xxx xxxxx
      Vxxxxxxxxxxxöxxx. KLAUSURHINWEIS:Exxx Sxöxxxxxxxxxx xxxx xxxxx
      xxxxxxxxxxxx xxxxxx. Axxxxx Axxxxxxxxx xxx xxx B xxxxxx xxxx xxxxx xx
      Bxxxxxxx. xx) Exxxxxxx P1 xxx P2 xüxxxxx xxxxxxxxxxxxxxxxxxx xxxxxxxxx
      xxxxx. Exxxxxxxxxxxxxx xxxx xxx Exxxxxxxxxxxxxxxxxxxxx, xxx
      Exxxxxxxxüxxxxxxxxxxxxx xxxxx xxx Exxxxxxxxxxxxxxxxxxxx. (1)
      Exxxxxxxxxxxxxxxxxxxxx Fxxxxxxx xxx, xx xxx Exxxxxxxxxxxxxxxxxxxxx xxxxxx.
      Exx Exxxxxxxxxxxxxxxxxxxxx xxxxx xxx, xxxx xxx Bxxöxxx xxxxxxxxxx xxx
      Exxxxxxx xxxxxxäxxx xxx, xxx xxxxxx xxxx üxxxxxxxx xxxxx xxxüxx. Fxxxxxxx
      xxx, xx § 8 Axx. 1 PxxG NRW xxx Bxxöxxx xxx Exxxxxxx xxxxäxxx. Dxx
      Wxxxxxxx xxx Nxxx xxxx „xxxx“ xxx Bxxöxxx xxx xxxxxxxxxxx Mxßxxxxxx
      xxxxxxx. Sxxxx xxxxx xxx Exxxxxxx xxxxxxxxxx xxx. Fxxxxxxx xxx, xx xxx
      Exxxxxxx üxxxxxxxx xxxxxüxx xxxxxx xxx. Hxxx xxxxxxx xxx P1 xxx P2 xxx B
      xxx, xxxx xxxxxx xxxxxxxxxxx xxxxxxxx xxxxxxxäxxx xxxüxxxxx Sxxxxxxxxxxxx
      xx Exxxxxxxxxx xxxxxxxx xxx xxx xx 23.10.2020 xxxxxx xxx Mxxxxxxäxxxxxx
      xxx xxx Sxxxxxxxxx xxxxxxx xxxxxx xxx. Axxxxxxx xxxxxx xxxxxx Uxxxäxxx
      xxxxx xxxx xxx P1 xxx P2 xxxx xxxxxxxxxxx, xxx Gxxäxxxxxxxxxxxxxx
      xxxxxxxxüxxxx. Exx Exxxxxxxxxxxxxxxxxxxxx xxxxx xxxxxxxx xxxxx xxx. (2)
      Exxxxxxxxüxxxxxxxxxxxxx Fxxxxxxx xxx, xx xxxx Exxxxxxxxüxxxxxxxxxxxxx
      xxxxxxxx. Exxx Exxxxxxxxüxxxxxxxxxxxxx xxxxxxxx, xxxx xxx Bxxöxxx xxxx
      Rxxxxxxxxxx xäxxx, xxx xxxxx xxxx xxx Gxxxxx xxxxxxx xxx. § 8 PxxG NRW
      xxxxxxx xxxxxxxxx xxx xxxxxxxxxxxxxx Mxßxxxxxx. Hxxxxxxxx xxxx xxxx
      Rxxxxxxxxx xxxxxxx, xxxxxx xxx Axxxxxxxxx xxx xxxxxxxxxx Txx, Dxxxxx xxxx
      Uxxxxxxxxxx xxxxxxxxxxx xxxxxxxx. Dxx Gxxäxxxxxxxxxxxxxx xxxxx xxxxx
      xxxxxxxxxxxx Rxxxxxxxxxx xxx xxxxx xxx xxxxxxxxxxx „Wxxxxxx“ xxx xxxx
      xxxxxxxxxxxx Rxxxxxxxxxx. Exxx Exxxxxxxxüxxxxxxxxxxxxx xxxxx xxxxx xxx.
      (3) Exxxxxxxxxxxxxxxxxxxx Fxxxxxxx xxx, xx xxx Exxxxxxxxxxxxxxxxxxxx
      xxxxxxxx. Exxxxxxxxxxxxxxxxxxxx xxxxx xxx, xxxx xxx Bxxöxxx Exxäxxxxxx xx
      xxxx Pxüxxxx xxx xxxxxxxxxx, xxx xxx Sxxx xxx Zxxxx xxx Nxxx xxxxx xxxxxxx
      xxxx. Dxx Pxxxxxxx xüxxxx „xxxxxxxx“ xxxxxxxxxx Exxäxxxxxx xxxxxxxxxxxxxx.
      Hxxx xxxxxxx xxx P1 xxx P2 xxxxxxxx xxx Uxxxxxxxx, xxxx xxx B xxxxx
      Sxxxxxxxxxxxxx xxxüxxxxx Mxxxxxxäxxxxxx xxxxxxxäxxx xxxxxxxxxxx xxx
      xxxxxxxäxxx xx Exxxxxxxxxx xxxxxxxx xxx xxx xx xxx xxxxx Lxxxxxxxxxxxxxx
      xxxxxxxxxxx xxxxxx xxx, xxxxxx xxx Gxxxxx xxxxx, xxxx B xxxxxxxx
      xxxxxxxäxxx xxxxxxäxxxx xxxxxx xxxx. Axxxxxxxxxxx Exxäxxxxxx xxxx xxxxx
      xxxxxxxxxxx. Exx Exxxxxxxxxxxxxxxxxxxx xxxxx xxxxx xxx. (4)
      Zxxxxxxxxxxxxxxx P1 xxx P2 xxxxxxxxx xxxxxxxxxxxxxxxxxxx. xx)
      Vxxxäxxxxxxäßxxxxxx Wxxxxxxxx xüxxxx xxx Gxxäxxxxxxxxxxxxxx
      xxxxäxxxxxxäßxx xxxx. Axx xxx Rxxxxxxxxxxxxxxxxxx xxxxx xxx Gxxxxxxxx,
      xxxx xxx Hxxxxxx xxx Vxxxxxxxxx xxxxäxxxxxxäßxx xxxx xxxx. (1) Lxxxxxxxx
      Zxxxx Axxxxxxxxxxxx xxxxx Vxxxäxxxxxxäßxxxxxx xxx xxxxx xxx Exxxxxxxxxx
      xxxxx xxxxxxxxx Zxxxxxx. Exxx Mxßxxxxx, xxx xxxxxxxx Zxxxx xxxxxxxx, xxxx
      xxxxx xxxxäxxxxxxäßxx xxxx. Dxx Mxßxxxxx xxxxxxxx xxxxx xxxxxxxxx Zxxxx,
      xxxx xxx xxxxxxxxxxx Zxxxx xxxx Exxxxx xxxxxxxxxxxxxxx xxx. Hxxx xxxxx xxx
      Gxxäxxxxxxxxxxxxxx xxx Zxxxx, Sxxxxxxxxxxxxxxx xxxxxxxxxxx. Sxxxx xxxxx
      xxx xxxxxxxxx Zxxxx xxx. (2) Gxxxxxxxxxxx Dxx Mxßxxxxx xxxx xxxxxxxx xxxx,
      xxx xxxxxxxx Zxxx xx xxxxxxxxx. Exxx Mxßxxxxx xxx xxxxxxxx xx Sxxxx xxx
      Vxxxäxxxxxxäßxxxxxx, xxxx xxx xüx xxx Exxxxxxxxx xxx Zxxxxxx xxx Mxßxxxxx
      xxxxxxxxx xxxxxxxx xxx, xxx Zxxxx xxxxxxxx üxxxxxxxx xxxxxxxxx xxxx. Exxx
      xxxxxxxxxxxxx Axxxxxxxx, xxx xxxxxxxxxx xxxxxxxxxxxxxxx Vxxxxxxxx xx
      Zxxxxxx xx xxxxxxxxxxx xxx xxxxxüxxxxxx, xxx Axxxxxxxxx xxx Mxßxxxxx xx
      Zxxxxxx xxxxxxxüxxxxx xx xxxxxxxxxx, xxx xxxxxxäxxxxxx xxxxxxxx, xxx B
      xxxx xx xxxxxxx, xxxxxx Kxxxxxx xx Mxxxxxxäxxxxxx xxxxxxxxxxxx. (3)
      Exxxxxxxxxxxxxxx Exxx Mxßxxxxx xxx xxxxxxxxxxxx xx Sxxxx xxx
      Vxxxäxxxxxxäßxxxxxx, xxxx xxx xxxxx xxxxxx xxxxxxxxxx Mxxxxxx xxx Mxxxxxxx
      xxx. Mxx xxx Gxxäxxxxxxxxxxxxxx, xxxxxx xxxxxxx xxxxx xxxxxxxxxxxx
      Rxxxxxxxxxx xxxxxxx, xxx xxxxxxxxxx xxx xxxxxxxx xx Bxxxxxxx xxxxxxxx
      Mxxxxx xxxxxxx P1 xxx P2 xxxäxxx xxxxxx. Fxxxxxxx xxx xxxx, xx xxxx xxxxx
      xxxx xxxxxxxxxxxx Mxxxxxxxxx xxx xxx xxxxxxxx Ixxxxx xxxxxxxxxxx xäxxx.
      Bxxxxxx xxx xxx Äxßxxxxx xxx B xxxxxxxxxxx, xxxx xxx xxxxöxxxxxx
      Gxxäxxxxxxxxxxxxxx xüx xxx xxxxxxxxxx xxx, xxxxxx xxxx xxxx, xxxx xxx
      Wxxxxxx xxx xxxxxxxxxxxx Ixxxxxxxxxx xx Rxxxxx xxxxx xxxxöxxxxxxx
      Axxxxxxxx xxxxxäxxx xxxxx. Dxx xxxxöxxxxxx Exxxxxxxxx xxx Pxxxxxxxxxxxxx
      xxxxäxxxxx xxx Dxxxxxxxxxxxx xxx xxx Exxxxxxxxxxxxxx xxx Sxxxxxxxx.
      Mxxxxxx xxx xxxxxx xxxxxxxxx Mxxxxx xxxx xxxx xxxxx xxxxxxxxxxx. (4)
      Vxxxäxxxxxxäßxxxxxx xx xxxxxxx Sxxxx (Axxxxxxxxxxxxx) Axxxxxxxßxxx xxxx
      xxx Mxßxxxxx xxxxxxxxxx – xxxx xxxx xxx xxxxäxxxxxxäßxx xx xxxxxxx Sxxxx
      xxxxxxxxxx – xxxx. Hxxx xxxxxxx xxxx xxxxxxxx Axxäxxxx xxx
      xxxxxxxxxxxxxxxx xxxxxxxxxxx Ixxxxxxxxx. Dxx Mxßxxxxx xxx xxxxäxxxxxxäßxx
      xx xxxxxxx Sxxxx, xxxx xxx xxxxx xxßxx Vxxxäxxxxx xxx xxxxxxxxxx Exxxxx
      xxxxx. Kxxxxxx xxxxxxxx xxxx, xxxx xxxx Mxßxxxxx xxxxxxxxxx xx Sxxxx xxx
      Vxxxäxxxxxxäßxxxxxx xxx, xxxx xxx Güxxxxxxäxxxx, xxxxxx xxxxxxxx xxx
      xxxüxxxxxxxxxxx Ixxxxxxxxxxüxxxx xxx. xxx Güxxxx xxx Axxxxxxxxxxxx
      xxxxxxxxxx xxx xxxxx xxx Bxxxxxxxxxx xxxxxxxxxxxx xx xxxxxxxx xxx, xx
      Gxxxxxx xxx xxxxxxxxxxxxx xxxxäxxx. Gxxxx xxx Vxxxäxxxxxxäßxxxxxx xxxxxxx,
      xxxx xxx xüxxxxx Axxxäxxxxxxxx xxx B xx Jxxxx 2007 xxxxxxxx, xxxxx xxxxxxx
      13 Jxxxx xxx xxx. Füx xxxx Vxxxäxxxxxxäßxxxxxx xxxxxxx, xxxx xxx B
      xxxxxxxxxxx xx xxxxx xxxxxxxxxxx Uxxxxx xx Exxxxxxxxxx xxxxxxxx xxx. Ex
      xxxxxxxx xxxxxx Vxxxxxxx xxx xxxx xxxxxxxxäxxxxx xxxxxxxäxxxx
      Vxxxxxxxxxxxxx. Hxxxx xxxxx, xxxx xxx Vxxxxxxxxxxx xxxxxxxxxxxxxx
      xxxxxxxxxxxxxxxxxxxxx Vxxxxößx xxx Gxxxxxxxxx xxxxx. Hxxxxxx xxxxx, xxxx
      xxx Rüxxxxxxxxx xxx xxxx xäxxxxxxx Nxxxxxx xxx B xxxxxx xxxxxxxxxxx xxx
      xxxxxxxxxxxxxx xxx, xxxx xxxxxxxxxxxx xx Hxxxxxxx xxxxxx, xxxx xxx B
      xxxxxxx xxxxx Kxxxxx xxx xxx Bxxxxxxxx xxxxxxxxxxxx xxxxx xxx xxxxx xxxx
      xxxxxxxxxxx Vxxxxxxxxxxxxxxxxx xüx xxxxxxxxxxxxx xxxxxxx xxxxxxxxxx
      Sxxxxxxxxx xxxxxxx xxxxx, xxxx xxx Ixxxxxxxxx xxx Axxxxxxxxxxxx xx xxxxx
      xxäxxxxxxxx xxxxxxxxxxxxx Mxßxxxxx xxx Ixxxxxxxxx xxx B üxxxxxxxxx. P1 xxx
      P2 xxxxxxxxx xxxxx xxxxxxxxx xxxxäxxxxxxäßxx. xx) Zxxxxxxxxxxxxxxx Dxx
      Gxxäxxxxxxxxxxxxxx xxx xxxxxxxxx-xxxxxxxxx xxxxxxäßxx. x) Gxxxxxxxxxxxxx
      Dxx Gxxäxxxxxxxxxxxxxx xxx xxxxxxxxx xxxxxxäßxx.
    </div>
  </div>
</template>

<script>
export default {
  name: "PreloaderHidden",
  data() {
    return {
      sticky: false,
    };
  },
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleScroll() {
      const overlay = this.$refs.overlay;
      const pos = overlay.getBoundingClientRect();
      if (pos.y >= 0) {
        this.sticky = true;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.$refs.overlay.getBoundingClientRect().top <= 0) {
      this.sticky = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.wrapper {
  position: relative;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  .locked-text {
    color: transparent;
    text-shadow: 0 0 8px $black;
    min-height: 350px;
  }

  .subscription-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transition: all 0.5s ease-in;
    &.sticky {
      position: sticky;
      top: 40%;
      bottom: 0;
      left: 0;
    }

    .subscription-banner {
      margin-top: 0;
      justify-content: center;
      padding-left: 80px;
      padding-right: 80px;
      width: 100%;
      background: url("~@/assets/background.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      align-items: center;
      padding-left: 105px;
      padding-right: 105px;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      @media screen and (min-width: 1200px) {
        flex-wrap: wrap;
      }
      .images {
        width: 250px;
      }
      .access-content {
        width: 100%;
        margin-left: 0px;
        margin-top: 15px;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        color: $white;

        .title {
          font-size: 13px;
          font-weight: bold;
          line-height: 1.1;
          white-space: nowrap;
          @media screen and (min-width: 1300px) {
            font-size: 35px;
          }
        }

        .btn-custom {
          background-color: $white;
          text-transform: uppercase;
          color: $black;
          font-weight: bold;
          border-radius: 25px;
          margin-top: 15px;
          border: 2px solid $white;
          cursor: pointer;
          padding: 11px 20px;
          text-align: center;
          font-size: 14px;
          box-shadow: 0px 2px 11px -1px #00000038;
          transition: all 0.3s ease;

          &:hover {
            background: transparent;
            color: $white;
          }
        }
      }
    }
  }
}
</style>
