<template>
    <div>
        <div class="info-card">
            <div class="row">
                <div class="col">
                    <div class="title" v-html="title"></div>
                    <hr />
                    <div class="description" v-html="description"></div>
                </div>
            </div>
        </div>
        <div v-if="button" class="button d-flex justify-content-center mt-4">
            <Button
                :variant="button.variant"
                :label="button.label"
                :action="button.action"
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue';

export default {
    name: "InfoCard",
    components: {
        Button,
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        button: {
            type: Object
        }
    }
}
</script>

<style lang="scss" scoped>

.info-card {
    width: 100%;
    border-radius: 10px;
    background: $aliceblue;
    border: 1px $gray dotted !important;

    .row {
        display: flex;
        padding: 25px;
        flex-wrap: wrap;

        .col {
            flex: 1 0 0%;

            .title {
                margin: 0px;
                font-weight: 600;
                font-size: 1rem;

                @media (min-width: 768px) {
                    font-size: 1.125rem;
                }

                @media (min-width: 1200px) {
                    font-size: 1.375rem;
                }
            }

            hr {
                margin: 25px -25px;
                border-bottom: transparent;
                box-shadow: transparent !important;
                border-top: 1px dotted $gray;
            }

            .description {
                margin-bottom: 0;
            }
        }
    }
}
</style>
