<template>
  <div class="subscription-card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SubscriptionCard",
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.subscription-card {
  padding: 15px;
  background: white;
  border: 1px solid rgba(127, 140, 141, 0.4);
  border-radius: 7px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: -2px 0px 23px -6px #00000026;
  font-size: 16px;
  color: $black;
  font-weight: 400;
  line-height: 1.5;
}
</style>
