<template>
  <div class="view content-padding">
    <h1>{{ labels.components_goes_here }}</h1>

    <hr />
    <p>
      Performed by Hirniak Andrii, path: @/components/Media/VideoInputPlayer
      <VideoInputPlayer
        :video="labels.video_input_player_path"
        :upload="labels.video_input_player_upload"
        :text="labels.video_input_player_text"
      />
    </p>
    <hr />

    <hr />
    <p>
      Performed by Hirniak Andrii, path: @/components/Media/MediaPostList
      <MediaPostList @addAudios="addAudios" :cards="cards" />
    </p>
    <hr />

    <ProfileUploadImage :btn-label="labels.change_image" />

    <NewsletterForm
      :title="news_letter_form_text"
      :paragraph="news_letter_form_paragraph"
      :bachelor="news_letter_form_bachelor"
      :hashtags="['#deinstudium', '#deinelernplattform', '#allinone']"
    />

    <MobileHeader />

    <TrainerCard
      :innerImage="labels.trainer_card_inner_image"
      :backgroundImage="labels.trainer_card_background_image"
      :definition="labels.trainer_card_definition"
      :cardTraining="labels.trainer_card_card_training"
    />

    <TeamCard
      :name="labels.team_card_name"
      :profession="labels.team_card_profession"
      :image="labels.team_card_image"
    />

    <ModuleHeader
      :title="labels.module_header_title"
      :subTitle="labels.module_header_subTitle"
    />

    <Dropdown
      :list="[
        'North Rhine-Westphalia',
        'Baden Wuerttemberg',
        'Bavaria',
        'Berlin',
        'Brandenburg',
        'Bremen',
        'Hamburg',
      ]"
    />

    <CollapsibleList style="width: 400px" />

    <FormulateInput
      type="search"
      name="search"
      class="search-form-main"
      :placeholder="labels.search_placeholder"
    />

    <CircleLoader :loading="true" />

    <PreloaderHidden :locked="true" />

    <InfoCard
      :title="labels.info_card_title"
      :description="labels.info_card_description"
    />

    <BundleButton
      :data="{ label: 'Bavaria', status: false }"
      :status-label="labels.bundle_button_status_label"
    />

    <Button :label="labels.button_buy_now" variant="btn-sienna" />
    <Button
      :label="labels.button_buy_now"
      :backgroundColor="`${colorsHtml.silver}`"
      color="#353535"
    />

    <LabelButtonPanel
      :label="labels.label_button_panel_label"
      modal-id="remarkModal"
    />

    <RemarkPopup
      modal-id="remarkModal"
      :headerText="labels.remark_popup_header_text"
      :labelTitle="labels.remark_popup_label_title"
      :labelContent="labels.remark_popup_label_content"
      :titlePlaceHolder="labels.remark_popup_title_placeholder"
      :contentPlaceHolder="labels.remark_popup_content_placeholder"
    />

    <IndicatorsButton />

    <BlankCard />

    <MainCard :bgColor="`${colorsHtml.white}`" style="width: 160px" />

    <AudioPlayer />

    <font-awesome-icon icon="user-secret" />
    <font-awesome-icon icon="custom-user-circle" />
    <Card bgColor="#b14126" />
    <BaseCard :styles="{ borderRadius: '15px' }">{{
      labels.base_card_name
    }}</BaseCard>

    <CircleButton />

    <FormulateInput
      type="text-editor"
      error-behavior="live"
      validation="required"
      validation-name="Text"
      :title="labels.text_editor"
      @changed="textEditorChanged"
    />

    <!-- POPUP EXAMPLE -->
    <Popup
      class="popup-example"
      ref="popupExampleRef"
      modal-id="exampleModal"
      :on-close="onModalClose"
      size="xl"
      aria-hidden="false"
    >
      <template v-slot:header>{{ labels.popup_example_header }}</template>
      <template v-slot:body> {{ labels.popup_example_body }} </template>
      <template v-slot:footer>
        <button
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="onModalClose"
        >
          {{ labels.popup_example_close }}
        </button>
      </template>
    </Popup>
    <button
      type="button"
      class="btn btn-primary mb-5"
      data-toggle="modal"
      data-target="#exampleModal"
    >
      {{ labels.open_modal }}
    </button>

    <NotesPopup modal-id="notesModal" :footer-text="labels.footer_text" />
    <button
      type="button"
      class="btn btn-primary mb-5"
      data-toggle="modal"
      data-target="#notesModal"
    >
      {{ labels.open_notes_modal }}
    </button>

    <button
      type="button"
      class="btn btn-primary mb-5"
      data-toggle="modal"
      data-target="#warningPopup"
    >
      {{ labels.open_warning_popup }}
    </button>

    <WarningPopup :loggedOut="true" />
    <Notifications
      variant="register-error"
      :errors="[
        'Du musst unseren Nutzungsbedingungen zustimmen, um fortfahren zu können.',
        'Du musst unserer Datenschutzerklärung zustimmen, um fortfahren zu können.',
        'Vorname ist erforderlich!',
        'Nachname ist erforderlich!',
        'Das Passwort darf nicht kürzer als 8 Zeichen sein!',
        'Ein solcher Benutzername oder eine solche E-Mail-Adresse wurde bereits vergeben!',
      ]"
    />

    <button
      type="button"
      class="btn btn-primary mb-5"
      data-toggle="modal"
      data-target="#subscriptionModal"
    >
      {{ labels.open_subscription_modal }}
    </button>

    <SubscriptionPopup :locked="true" modal-id="subscriptionModal" />

    <button
      type="button"
      class="btn btn-primary mb-5"
      data-toggle="modal"
      data-target="#newsletterSubscriptionModal"
    >
      {{ labels.open_new_letter_subscription_modal }}
    </button>

    <NewsletterSubscriptionPopup
      :locked="true"
      modal-id="newsletterSubscriptionModal"
    />

    <WarningPopup :loggedOut="true" />
    <Notifications
      variant="register-error"
      :errors="[
        'Du musst unseren Nutzungsbedingungen zustimmen, um fortfahren zu können.',
        'Du musst unserer Datenschutzerklärung zustimmen, um fortfahren zu können.',
        'Vorname ist erforderlich!',
        'Nachname ist erforderlich!',
        'Das Passwort darf nicht kürzer als 8 Zeichen sein!',
        'Ein solcher Benutzername oder eine solche E-Mail-Adresse wurde bereits vergeben!',
      ]"
    />

    <LineChart />

    <SubscriptionPriceNote :price="14.98" />
    <SubscriptionAgreement />
    <Footer />
  </div>
</template>

<script>
import VideoInputPlayer from "@/components/Media/VideoInputPlayer";
import MediaPostList from "@/components/Media/MediaPostList";
import ProfileUploadImage from "@/components/Input/ProfileUploadImage";
import NewsletterForm from "@/components/Forms/NewsletterForm";
import TrainerCard from "@/components/Cards/TrainerCard";
import TeamCard from "@/components/Cards/TeamCard.vue";
import ModuleHeader from "@/components/Header/ModuleHeader";
import MobileHeader from "@/components/Header/MobileHeader";
import Dropdown from "@/components/Dropdowns/Dropdown";
import BaseCard from "@/components/Cards/BaseCard";
import Popup from "@/components/Popups/Popup";
import Footer from "@/components/Footer/Footer";
import CollapsibleList from "@/components/List/CollapsibleList.vue";
import CircleLoader from "@/components/Preloaders/CircleLoader";
import PreloaderHidden from "@/components/Preloaders/PreloaderHidden";
import NotesPopup from "@/views/Account/Common/Notes";
import SubscriptionPopup from "@/views/Account/Common/SubscriptionPopup";
import NewsletterSubscriptionPopup from "@/views/Account/Common/NewsletterSubscriptionPopup";
import LineChart from "@/components/Chart/LineChart";
import BlankCard from "@/components/Cards/BlankCard";
import Card from "@/components/Cards/Card.vue";
import CircleButton from "@/components/Buttons/CircleButton";
import LabelButtonPanel from "@/components/Panels/LabelButtonPanel";
import IndicatorsButton from "@/components/Buttons/IndicatorsButton";
import Button from "@/components/Buttons/Button.vue";
import AudioPlayer from "@/components/Media/AudioPlayer";
import InfoCard from "@/components/Cards/InfoCard.vue";
import BundleButton from "@/components/Buttons/BundleButton";
import MainCard from "@/components/Cards/MainCard.vue";
import WarningPopup from "@/components/Popups/WarningPopup.vue";
import RemarkPopup from "@/views/Account/Common/RemarkPopup";
import Notifications from "@/components/Helpers/Notifications.vue";
import SubscriptionPriceNote from "@/components/Subscription/SubscriptionPriceNote.vue";
import SubscriptionAgreement from "@/components/Subscription/SubscriptionAgreement.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { customUserCircle } from "@/assets/icons";
library.add(faUserSecret, customUserCircle);
import colorsHtml from "/colors.config.json";
export default {
  name: "ComponentsTest",
  components: {
    VideoInputPlayer,
    MediaPostList,
    ProfileUploadImage,
    TrainerCard,
    ModuleHeader,
    Dropdown,
    BaseCard,
    Popup,
    Footer,
    CollapsibleList,
    CircleLoader,
    PreloaderHidden,
    NotesPopup,
    LineChart,
    BlankCard,
    MainCard,
    Card,
    CircleButton,
    LabelButtonPanel,
    AudioPlayer,
    IndicatorsButton,
    Button,
    InfoCard,
    BundleButton,
    RemarkPopup,
    SubscriptionPopup,
    NewsletterSubscriptionPopup,
    WarningPopup,
    TeamCard,
    MobileHeader,
    Notifications,
    SubscriptionPriceNote,
    SubscriptionAgreement,
    NewsletterForm,
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      cards: [
        {
          img: "https://assets.medpagetoday.net/media/images/94xxx/94222.jpg",
          title: "lorem ipsum",
          name: "Lorem_ipsum.mp3",
          label: "Title:",
          link: "/",
          action: () => {},
        },
        {
          img: "https://assets.medpagetoday.net/media/images/94xxx/94222.jpg",
          title: "lorem ipsum",
          name: "Lorem_ipsum.mp3",
          label: "Title:",
          link: "/",
          action: () => {},
        },
        {
          img: "https://assets.medpagetoday.net/media/images/94xxx/94222.jpg",
          title: "lorem ipsum",
          name: "Lorem_ipsum.mp3",
          label: "Title:",
          link: "/",
          action: () => {},
        },
        {
          img: "https://assets.medpagetoday.net/media/images/94xxx/94222.jpg",
          title: "lorem ipsum",
          name: "Lorem_ipsum.mp3",
          label: "Title:",
          link: "/",
          action: () => {},
        },
      ],
      labels: {
        components_goes_here: "Components goes here: ",
        video_input_player_path: "https://www.youtube.com/embed/DYzT-Pk6Ogw",
        video_input_player_upload: "Upload : ",
        video_input_player_text: "Lorem ipsum dolor sit amet",
        change_image: "Change image",
        news_letter_form_text: "So wird Recht einfach",
        news_letter_form_paragraph:
          "Bleib Up-to-Date für alle Skripte und Übungsfälle (samt Musterlösungen) für die Rechtsfächer im",
        news_letter_form_bachelor:
          "Bachelorstudiengang Polizeivollzugsdienst NRW",
        trainer_card_inner_image: "def-police.png",
        trainer_card_background_image: "def-bg.jpg",
        trainer_card_definition: "Definition coach",
        trainer_card_card_training: "Cards training",
        team_card_name: "Verena Colesie",
        team_card_profession: "Rechtsanwältin, Autorin",
        team_card_image:
          "https://recht-einfach.schule/wp-content/uploads/2021/05/9.png",
        module_header_title:
          "GS 4.1: Einführung in die strafrechtliche Dogmatik",
        module_header_subTitle: "04/02/2022 - Marius Brinkmann, LL.M.",
        search_placeholder: "Search placeholder",
        info_card_title: "Lorem ipsum dolor",
        info_card_description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea rerum dolorum eius suscipit consequatur enim consequuntur nostrum aspernatur doloribus ducimus expedita quasi numquam ut harum, omnis voluptatibus ex aliquid recusandae.",
        bundle_button_status_label: "Coming soon",
        button_buy_now: "Buy now",
        label_button_panel_label: "Remarks",
        remark_popup_header_text: "Preparing for the section process",
        remark_popup_label_title: "Note title",
        remark_popup_label_content: "Note content",
        remark_popup_title_placeholder: "Note title...",
        remark_popup_content_placeholder: "Note content...",
        base_card_name: "BaseCard",
        text_editor: "Text editor",
        popup_example_header: "Header",
        popup_example_body: "lorem10000",
        popup_example_close: "Close",
        open_modal: "Open modal",
        open_notes_modal: "Open notes modal",
        open_warning_popup: "Open warning popup",
        footer_text: "1. Checkliste: Bewerbung",
        open_subscription_modal: "Open SubscriptionModal",
        open_new_letter_subscription_modal: "Open newsletterSubscriptionModal",
      },
    };
  },
  methods: {
    onModalClose() {
      console.log("Modal closed");
      // this.$router.go( -1 );
    },
    textEditorChanged(ev) {
      console.log(ev);
    },
    addAudios(value) {
      value.forEach((item) => {
        this.$set(this.cards, this.cards.length, {
          img: "https://assets.medpagetoday.net/media/images/94xxx/94222.jpg",
          title: item.name.slice(0, -4),
          name: item.name,
          label: "Title:",
          link: "/",
          action: () => {},
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
